@mixin modifiers {
  &--light {
    color: $white-color;
  }
  &--dark {
    color: $black-color;
  }
  &--primary {
    color: $primary-color;
  }
}
