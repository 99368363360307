.footer {
  grid-column: full-start / full-end;

  display: grid;
  grid-template-columns: $column-template;
  padding: 5rem 0;
  background-color: $white-color;
  border-top: 1px solid $border-color;
  &__container {
    grid-column: col-start 1 / col-end 8;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content max-content;
    grid-row-gap: 3rem;
    grid-column-gap: 3rem;
    @media only screen and (max-width: $bp-tablet) {
      grid-column: table-start / table-end;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, max-content);
    }
    @media only screen and (max-width: $bp-large-mobile) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, max-content);
    }
  }

  &__copyright {
    display: grid;
    grid-template-rows: minmax(0, max-content) 1fr;
    grid-row-gap: 3rem;
  }
  &__copyright-item {
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: $primary-color;
    }
    @media only screen and (max-width: $bp-tablet) {
      grid-row: 3/4;
      grid-column: 1/-1;
      justify-self: center;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      grid-row: -2/-1;
      grid-column: 1/-1;
      justify-self: center;
    }
  }
  &__img-container {
    @media only screen and (max-width: $bp-large-mobile) {
      display: flex;
      justify-content: center;
    }
  }
  &__img {
    height: 100%;
    object-fit: contain;
    max-height: 66px;
  }
  &__description {
    font-size: 1.4rem;
    color: $secondary-color;
    @media only screen and (max-width: $bp-large-mobile) {
      text-align: center;
    }
  }
  &__menu {
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 2rem;
    justify-self: center;
    grid-row: 1/-1;
    grid-column: 2/3;
    @media only screen and (max-width: $bp-tablet) {
      grid-column: 1/-1;
      grid-row: 2/3;
      width: 70%;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      grid-column: 1/-1;
      width: 100%;
    }
  }
  &__menu .nav {
    height: 100%;
    grid-row: 1/2;
    grid-column: 1/2;
  }

  &__menu .nav__login {
    display: block;
  }

  &__menu .nav__list {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    align-content: space-between;
    @media only screen and (max-width: $bp-tablet) {
      position: relative;
      background-image: none;
      width: 100%;
      right: initial;
      transform: none;
      padding-top: 0;
      display: flex;
    }
    @media only screen and (max-width: $bp-mobile) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__menu .nav__close,
  &__menu .nav__wave,
  &__menu .nav__copyright {
    display: none;
  }
  &__menu .nav__logo {
    display: none;
  }
  &__menu .nav__item {
    padding: 0;
    &:first-of-type {
      margin-top: 0;
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.2rem;
    }
    @media only screen and (max-width: $bp-mobile) {
      font-size: 1.6rem;
      padding: 1rem;
    }
  }
  &__menu .nav__item.active {
    color: $primary-color !important;
  }

  &__menu .nav__btn {
    display: none;
  }

  &__map {
    width: 100%;
    height: 100%;
    grid-row: 1/-1;
    grid-column: 3/-1;
    @media only screen and (max-width: $bp-tablet) {
      grid-row: 1/2;
      grid-column: 2/3;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      grid-row: 3/4;
      grid-column: 1/-1;
      min-height: 30rem;
      max-height: 30rem;
    }
  }
}
