.offer {
  grid-column: col-start 1 / col-end 8;

  display: grid;
  row-gap: 4rem;
  grid-template-rows: max-content 1fr;
  padding: 10rem 0;

  @media screen and (max-width: $bp-desktop) {
    padding: 5rem 0;
  }

  @media screen and (max-width: $bp-tablet) {
    grid-column: table-start / table-end;
  }

  &__heading {
    grid-column: 1 / -1;
  }

  &__products {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    grid-gap: 3rem;
    @media screen and (max-width: $bp-tablet) {
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
  }
}
