.u-center-text {
  text-align: center !important;
}
.u-uppercase-text {
  text-transform: uppercase !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-big {
  margin-bottom: 6rem !important;
}
.u-margin-bottom-huge {
  margin-bottom: 10rem !important;
}

.u-margin-top-small {
  margin-top: 1.5rem !important;
}
.u-margin-top-medium {
  margin-top: 4rem !important;
}
.u-margin-top-big {
  margin-top: 6rem !important;
}
.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-color-primary {
  color: $primary-color !important;
}
