.about-us {
  grid-column: col-start 1 / col-end 8;
  padding: 10rem 0;
  @media screen and (max-width: $bp-desktop) {
    padding: 5rem 0;
  }
  @media screen and (max-width: $bp-tablet) {
    grid-column: table-start / table-end;
  }

  &__heading {
    margin-bottom: 3rem;
  }
  &__content {
  }
  &__description {
  }
}
