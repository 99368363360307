$heading-font: 'Roboto Slab', serif;
$main-font: 'Roboto', sans-serif;

$primary-color: #f7c335;
$secondary-color: #7f8a9a;
$background-color: #fafafc;
$border-color: #e6e8eb;
$white-color: #fff;
$black-color: rgba(0, 0, 0, 0.8);
$error-color: #ff0000;
$box-shadow: 0 3px 6px rgba($black-color, 0.16);
$gradient-color: #f38628;

// GRID
$column-template: [full-start] minmax(5vmin, 1fr) [table-start] minmax(5vmin, 1fr) repeat(8, [col-start] minmax(0, 14.25rem) [col-end]) minmax(5vmin, 1fr)
  [table-end] minmax(5vmin, 1fr) [full-end];

// RWD
$bp-desktop: 75em;
$bp-large-tablet: 68.75em;
$bp-tablet: 62.5em;
$bp-large-mobile: 43.75em;
$bp-mobile: 30em;
