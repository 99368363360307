.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, max-content);
  grid-column-gap: 3rem;
  @media screen and (max-width: $bp-desktop) {
    grid-column: 1/-1;
  }
  @media screen and (max-width: $bp-mobile) {
    grid-template-columns: 1fr;
  }
  &__control {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    &--textarea {
      grid-column: 1/-1;
    }
  }
  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  &__input,
  &__textarea,
  &__error-field {
    padding: 1.3rem 2rem;
    border: none;
    box-shadow: $box-shadow;
    border-radius: 4px;
    font-size: 1.4rem;
    font-family: inherit;
    border: 2px solid $white-color;
    &::placeholder {
      color: $secondary-color;
    }
  }
  &__textarea {
    resize: none;
    min-height: 16rem;
  }
  &__btn {
    grid-row: 3/4;
    justify-self: start;
    @media screen and (max-width: $bp-desktop) {
      justify-self: stretch;
      margin-top: 2rem;
    }

    @media screen and (max-width: $bp-mobile) {
      grid-row: -1/-1;
    }
  }
  &__error,
  &__error-field {
    border: 2px solid $error-color;
  }
  &__error-field {
    margin-top: 1rem;
    background-color: $error-color;
    color: $white-color;
    font-size: 1.4rem;
    font-weight: bold;
  }
}
