.btn {
  border: none;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1.6rem;
  padding: 1.1rem 4.5rem;
  transition: 0.2s ease-in-out;
  font-weight: bold;
  cursor: pointer;
  &__primary {
    color: $white-color;
    background-color: $primary-color;
    &:hover {
      color: $primary-color;
      background-color: $white-color;
    }
  }
}
