.hero {
  grid-column: col-start 1 / col-end 8;

  display: grid;
  grid-template-rows: minmax(5rem, 1fr) repeat(4, max-content) minmax(5rem, 1fr);
  justify-items: center;
  text-align: center;

  @media only screen and (max-width: $bp-tablet) {
    grid-column: table-start / table-end;
    grid-template-rows: minmax(5vmin, 1fr) repeat(4, max-content) minmax(5vmin, 1fr);
  }
  &__img {
    grid-column: 1/-1;
    grid-row: 2 / span 4;
    opacity: 0.1;
    z-index: 0;
    max-height: 35rem;
  }
  &__header {
    grid-column: 1/-1;
    z-index: 1;
    &--primary {
      grid-row: 3/4;
      @media only screen and (max-width: $bp-tablet) {
        font-size: 3.2rem;
      }
    }
    &--secondary {
      grid-row: 4/5;
      font-weight: 400;
      @media only screen and (max-width: $bp-tablet) {
        font-size: 2rem;
      }
    }
  }
  &__btn {
    z-index: 1;
    grid-column: 1/-1;
    grid-row: 5/6;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }
}
