.contact {
  grid-column: col-start 1 / col-end 8;
  padding: 10rem 0;
  display: grid;
  row-gap: 4rem;
  @media screen and (max-width: $bp-desktop) {
    grid-column: table-start/table-end;
    padding: 5rem 0;
  }
  &__info {
    @media screen and (max-width: $bp-desktop) {
      display: grid;
      column-gap: 3rem;
      grid-template-columns: 1fr 1fr;
      grid-row: 1/-1;
      grid-column: 1/-1;
    }
  }
  &__form {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 3rem;
  }

  &__info-link {
    transition: 0.2s ease-in-out;
    &:hover {
      color: $secondary-color;
    }
  }

  &__info-header {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }

  &__info-link,
  &__text {
    display: flex;
    font-size: 1.6rem;
    color: $black-color;
  }
  &__header {
    &--tablet {
      margin: 3.6rem 0;
      grid-column: 1/-1;
      @media screen and (min-width: $bp-desktop) {
        display: none;
      }
    }
  }
  &__opening &__header {
    margin-bottom: 3rem;
  }

  &__opening-item {
    display: flex;
    justify-content: space-between;
    width: 40%;
    @media screen and (max-width: $bp-large-mobile) {
      width: 100%;
    }
  }
  &__map {
    width: 100%;
    min-height: 30rem;
  }
}
