.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 1rem;

  &__item {
    text-decoration: none;
    position: relative;
    cursor: pointer;
  }
  &__item:hover &__hover {
    opacity: 1;
    visibility: visible;
    @media only screen and (max-width: $bp-tablet) {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  &__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($black-color, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    color: $white-color;
    @media only screen and (max-width: $bp-tablet) {
      opacity: 0;
    }
  }
  &__icon {
    height: 7rem;
    width: 7rem;
  }
}
