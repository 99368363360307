.box {
  display: grid;
  grid-template-rows: max-content minmax(0, max-content);
  background: $white-color;
  box-shadow: $box-shadow;
  border-radius: 0.4rem;
  &_image_container {
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 18rem;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    &_image {
      border-bottom: 1px solid $border-color;
      width: 100%;
      height: auto;
    }
    &_price {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
      position: absolute;
      font-family: $heading-font;
      z-index: 100;
      left: 0;
      top: 2rem;
      padding: 0 2.3rem;
      height: 3rem;
      background-color: $primary-color;
      color: $white-color;
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .description_container {
    padding: 2rem;
    &_title {
      font-family: $heading-font;
      font-size: 2rem;
      font-weight: bold;
      color: rgba($black-color, 0.8);
    }
    &_description {
      font-size: 1.4rem;
      font-weight: regular;
      color: $secondary-color;
    }
  }
}
