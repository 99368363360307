.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--app-height);
  width: 100%;
  background-color: rgba($black-color, 0.5);
  z-index: 9999999;

  display: flex;
  align-items: center;
  justify-content: center;

  &__box {
    background-color: $white-color;
    border-radius: 4px;
    overflow: hidden;
    width: 80rem;

    @media only screen and (max-width: $bp-tablet) {
      width: 95%;
    }
  }

  &__heading {
    background-color: $black-color;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header {
    @media only screen and (max-width: $bp-mobile) {
      font-size: 1.6rem;
    }
  }

  &__close {
    font-size: 3rem;
    color: $white-color;
    cursor: pointer;
    @media only screen and (max-width: $bp-mobile) {
      font-size: 1.6rem;
    }
  }

  &__body {
    padding: 3rem;
    display: grid;
    grid-row-gap: 3rem;
    @media only screen and (max-width: $bp-large-mobile) {
      padding: 3rem 1.5rem;
    }
  }

  &__person {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
    grid-column-gap: 1.5rem;

    @media only screen and (max-width: $bp-large-mobile) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, max-content);
      justify-items: center;
    }
  }
  &__avatar {
    max-height: 8rem;
    grid-row: 1/-1;
    @media only screen and (max-width: $bp-large-mobile) {
      grid-row: 1/2;
    }
  }
  &__author {
    align-self: flex-end;
    font-size: 2rem;
    @media only screen and (max-width: $bp-large-mobile) {
      grid-row: 2/3;
      text-align: center;
    }
  }
  &__link {
    grid-row: 2/3;
    grid-column: 2/3;
    text-decoration: none;
    font-size: 1.4rem;
    color: $gradient-color;
    transition: 0.2s ease-in-out;
    justify-self: start;
    &:hover {
      color: $black-color;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      grid-row: 3/4;
      grid-column: 1/-1;
      justify-self: center;
    }
  }
}
