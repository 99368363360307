.recommend {
  grid-column: full-start / full-end;
  background-color: $white-color;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  padding: 4rem 0;

  display: grid;
  grid-template-columns: $column-template;
  grid-template-rows: max-content max-content;
  grid-row-gap: 4rem;

  &__header {
    grid-column: col-start 1 / col-end 8;
    @media only screen and (max-width: $bp-tablet) {
      grid-column: table-start / table-end;
    }
  }

  &__container {
    grid-column: col-start 1 / col-end 8;
    grid-row: 2 / span 1;
    display: grid;
    grid-template-rows: max-content;
    grid-auto-flow: column;
    grid-column-gap: 3rem;
    scroll-behavior: smooth;
    margin-left: -1.5rem;
    @media only screen and (max-width: $bp-tablet) {
      grid-column: table-start / table-end;
    }
  }

  .carousel {
    width: 100%;
    display: grid;
  }

  &__item {
    display: grid;
    grid-template-rows: repeat(3, max-content);
    grid-row-gap: 1rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  &__label {
    background-color: $primary-color;
    z-index: 1;
    align-self: flex-start;
    justify-self: flex-start;
    margin-top: 2rem;
    padding: 0.2rem 2.4rem;
    font-size: 2rem;
    font-family: $heading-font;
    font-weight: bold;
    color: $white-color;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &__label,
  &__img {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  &__img {
    max-height: 17.8rem;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  &__title {
  }
  &__description {
    font-size: 1.4rem;
    color: $secondary-color;
  }
}
