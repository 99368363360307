.nav {
  &__list {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    justify-content: space-between;

    @media only screen and (max-width: $bp-tablet) {
      position: fixed;
      height: var(--app-height);
      background-image: linear-gradient(to bottom, $gradient-color, $primary-color 30%);
      top: 0;
      width: 50%;
      z-index: 999999;
      grid-template-columns: 1fr max-content;
      grid-template-rows: max-content repeat(5, max-content) 1fr;
      row-gap: 2rem;
      right: 0;
      transform: translateX(100%);
      transition: 0.2s ease-in-out;
      padding-top: 10rem;
      justify-items: center;
      align-content: space-between;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      width: 100%;
      row-gap: 1rem;
      padding-top: 5rem;
    }
    &--visible {
      transform: translateX(0);
    }
  }
  &__item {
    font-size: 1.6rem;
    text-decoration: none;
    color: $secondary-color;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    padding: 1rem;
    &--disabled:hover {
      cursor: not-allowed;
      text-decoration: line-through;
      color: $black-color !important;
    }
    &:hover {
      color: $primary-color;
      @media only screen and (max-width: $bp-tablet) {
        color: $white-color;
      }
    }
    &:first-of-type {
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 10rem;
      }
      @media only screen and (max-width: $bp-large-mobile) {
        margin-top: 2rem;
      }
    }
    @media only screen and (max-width: $bp-tablet) {
      grid-column: 1/-1;
      color: $black-color;
    }
  }
  &__btn,
  &__close,
  &__wave,
  &__logo,
  &__copyright,
  &__login {
    display: none;
  }
  &__btn {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
      font-size: 3rem;
      border: none;
      background-color: transparent;
      justify-self: end;
      cursor: pointer;
    }
  }

  &__close {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
      grid-row: 1/2;
      grid-column: 2/3;
      margin-right: 4rem;
      z-index: 999999;
      background-color: transparent;
      border: none;
      font-size: 3rem;
      cursor: pointer;
    }
  }
  &__wave {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
      grid-row: -2/-1;
      grid-column: 1/-1;
      align-self: flex-end;
      padding-top: 10rem;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      padding-top: 2rem;
    }
  }
  &__logo {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
      grid-column: 1/-1;
      grid-row: 1/2;
      width: 100%;
      max-height: 8rem;
      object-fit: contain;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      max-height: 5rem;
    }
  }
  &__copyright {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
      grid-row: -2/-1;
      grid-column: 1/-1;
      align-self: flex-end;
      color: $white-color;
      margin-bottom: 7rem;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
