@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Roboto:wght@400;700&display=swap');

body {
  font-family: $main-font;
  font-weight: 400;
  line-height: 1.7;
}

%heading {
  font-family: $heading-font;
  font-weight: 700;
  line-height: 1.7;
}

.heading-1 {
  @extend %heading;
  font-size: 4.5rem;
  @include modifiers();
}

.heading-2 {
  @extend %heading;
  font-size: 4rem;
  color: $black-color;
  @include modifiers();
}

.heading-3 {
  @extend %heading;
  font-size: 2.4rem;
  color: $primary-color;
  @include modifiers();
}

.heading-4 {
  @extend %heading;
  font-size: 2rem;
  @include modifiers();
}

p {
  font-size: 1.6rem;
  line-height: 1.4;
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
}
