.header {
  grid-column: full-start / full-end;
  display: grid;
  grid-template-columns: $column-template;
  grid-auto-rows: minmax(0, 1fr);
  align-items: center;
  padding: 2.7rem 0;
  background-color: $white-color;
  border-bottom: 1px solid $border-color;
  &__logo {
    grid-column: col-start 1 / col-end 2;
    height: 100%;
    width: 100%;
    @media only screen and (max-width: $bp-tablet) {
      grid-column: table-start / col-end 2;
    }
  }
  &__img {
    height: 100%;
    object-fit: contain;
  }
  &__nav {
    grid-column: col-start 5 / col-end 8;
    @media only screen and (max-width: $bp-desktop) {
      grid-column: col-start 4 / col-end 8;
    }
    @media only screen and (max-width: $bp-tablet) {
      grid-column: col-end 8 / table-end;
      display: grid;
    }
    @media only screen and (max-width: $bp-large-mobile) {
      grid-column: col-end 8 / table-end;
    }
  }
}
.facebook-like-link {
  grid-column: 1/-1;

  display: grid;
  grid-template-columns: $column-template;
  border-bottom: 1px solid $border-color;
  padding: 1rem 0;
  background-color: #f3f3f3;
}

.fb_iframe_widget {
  grid-column: col-start 7 / col-end 8;
  justify-self: end;
}
