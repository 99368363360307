*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
:root {
  --app-height: 100%;
}

html {
  font-size: 62.5%;
  @media only screen and (max-width: $bp-tablet) {
    font-size: 55%;
  }
}

body {
  box-sizing: border-box;
  background-color: $background-color;
}

.container {
  display: grid;
  grid-template-columns: $column-template;
  grid-template-rows: 12rem repeat(7, max-content);
}

::selection {
  background-color: $primary-color;
  color: $white-color;
}

.active {
  color: $primary-color !important;
  @media only screen and (max-width: $bp-tablet) {
    color: $background-color !important;
  }
}
