.content {
  grid-column: col-start 1 / col-end 8;

  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 10rem;
  padding: 10rem 0;

  @media only screen and (max-width: $bp-tablet) {
    grid-column: table-start / table-end;
    padding: 5rem 0;
    grid-row-gap: 5rem;
  }
  &__title,
  &__sub-title {
    margin-bottom: 1.5rem;
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 0;
    }
  }
  &__sub-title {
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 1.5rem;
    }
  }
  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: max-content;
    grid-column-gap: 3rem;
    @media only screen and (max-width: $bp-tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content);
      grid-row-gap: 3rem;
    }
  }
  &__item--reverse &__img {
    grid-column: 2/3;
    @media only screen and (max-width: $bp-tablet) {
      grid-column: 1/-1;
    }
  }
  &__title {
    grid-row: 1/2;
    grid-column: 1/-1;
  }
  &__content {
    grid-row: 2/3;
    @media only screen and (max-width: $bp-tablet) {
      grid-row: auto;
      grid-column: 1/-1;
    }
  }
  &__description {
    grid-column: 1/-1;
  }
  &__img {
    height: 0;
    min-height: 22.6rem;
    width: 100%;
    grid-row: 2/3;
    grid-column: 1/2;
    object-fit: cover;
    @media only screen and (max-width: $bp-tablet) {
      grid-column: 1/-1;
    }
  }
}
